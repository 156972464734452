<template>
  <v-container>
    Error 404
  </v-container>
</template>

<script>
export default {
name: "Error404"
}
</script>